@import '~antd/dist/antd.less';

* {
  font-family: 'Playfair Display', serif;
}

.inline-button {
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
}

.dark-theme {
  color: @text-color-inverse;
}

.dark-theme .ant-typography {
  color: @text-color-inverse
}

.dark-theme .ant-divider {
  background-color: @text-color-inverse
}

.hero {
  background-color: @layout-header-background;
  color: @text-color-inverse;
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 50px;
}

.hero::before{
  content: "";
  background-image: url('assets/images/hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  opacity: 50%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  transform: scale(1.1);
  filter: blur(10px);
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
}

.hero .ant-typography { 
  color: @text-color-inverse
}

@primary-color: #ED6A5A;@layout-header-background: #2b303a;@layout-footer-background: @layout-header-background;@text-color-inverse: #FFF9EC;@layout-body-background: #FFFFFF;